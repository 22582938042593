/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC482P
 * 화면 설명: CDF관리 화면 - 유경력 면담기록부 확인
 * 화면 접근권한: [조회]지역단스텝(단장, 파트장, 지점장)
 * 작 성 일: 2022.07.15
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="유경력 면담기록부 보기" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box gray">
        <div class="row">
          <div class="title">
            <span>교육생</span>
          </div>
          <div class="value">
            <span>{{ pData450M.traineNm }}({{ pData450M.traineEno }})</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>입과월</span>
          </div>
          <div class="value">
            <span>{{ encrsYm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>지점</span>
          </div>
          <div class="value">
            <span>{{ pData450M.traineFofOrgNm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>작성</span>
          </div>
          <div class="value">
            <span>{{ pData450M.wrtNm }}({{ pData450M.wrtEno }})</span>
          </div>
        </div>
      </ur-box-container>

      <div  v-for="(ctgeItem,idx) in lv_InvwCtgeList" :key="idx" style="width:100%">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box p4524">
            <span class="txt-title">{{ ctgeItem.title }}</span>
            <div class="row" v-for="ctgeItemList in lv_InvwCtgeList[idx]" :key="ctgeItemList.invwQuestCd">
              <div class="title w120">
                <span v-html="ctgeItemList.title"></span>
              </div>
              <div class="value">
                <span>{{ ctgeItemList.addAnswCntnt }}</span>
              </div>
            </div>
        </ur-box-container>
      </div>

      <div style="width:100%" v-if="prspCust">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box p4524">
          <span class="txt-title">잠재고객</span>
          <div class="row">
            <div class="title w120">
              <span>{{ prspCust }}</span>
            </div>
            <div class="value"></div>
          </div>
        </ur-box-container>
      </div>

      <div style="width:100%" v-if="obsOpinTxt">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box p4524">
          <span class="txt-title">교육지점장 종합면담의견</span>
          <div class="row">
            <div class="title w120">
              <span>{{ obsOpinTxt }}</span>
            </div>
            <div class="value"></div>
          </div>
        </ur-box-container>
      </div>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  name: 'MSPRC482P',
  screenId: 'MSPRC482P',
  mixins:[Screen],
  components: {
    moment
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    pData450M: {
      type: Object
    }
  },
  data() {
    return {
      title: '',
      encrsYm: '', // 입과월
      obsOpinTxt: '', // 교육지점장 종합면담의견
      prspCust: '', // 잠재고객 데이터

      lv_InvwCntntList: [], // 카테고리별 셋팅
      lv_InvwCtgeList: [],  // 문항별 셋팅

      /** 유경력 질문지 **/
      // questCd: 임의 수정 금지, 관리자 페이지 엑셀 다운로드 영향이 있습니다. questCd 코드: 테이블 정의서(TTSR00022) 참조
      // 교육지점장 종합면담의견 문항은 정의된 questCd 코드가 없음.
      questList: [
        {idx:1,  questCd:'002', ctgeTitle:'경력',    title:'당사이력'},
        {idx:2,  questCd:'003', ctgeTitle:'경력',    title:'타사이력'},
        {idx:3,  questCd:'004', ctgeTitle:'경력',    title:'타사 근무기간'},
        {idx:4,  questCd:'005', ctgeTitle:'회사설명',  title:'수수료 제도'},
        {idx:5,  questCd:'006', ctgeTitle:'회사설명',  title:'30만 달성 수수료 수준'},
        // 신용문제 문항 => 전 직장 문항 대체 긴급요청(2022.08.16)
        // {idx:6,  questCd:'007', ctgeTitle:'속성',  title:'신용문제'},
        {idx:6,  questCd:'008', ctgeTitle:'속성',    title:'전 직장'},
        {idx:7,  questCd:'009', ctgeTitle:'속성',    title:'육아문제'},
        {idx:8,  questCd:'010', ctgeTitle:'속성',    title:'가족반대'},
        {idx:9,  questCd:'011', ctgeTitle:'속성',    title:'원거리'},
        {idx:10, questCd:'014', ctgeTitle:'잠재고객', title:''},
        {idx:11, questCd:'', ctgeTitle:'교육지점장 종합면담의견',  title:''},
      ],
      // 종합면답의견 답변 코드
      obsOpinList: [{value:'A', text:'우수'},{value:'B', text:'양호'},{value:'C', text:'보통'},{value:'D', text:'미흡'}] //
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    this.fn_Init()
    this.encrsYm = moment(this.pData450M.encrsYm).format('YYYY-MM')
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 화면 진입시, 최초실행 함수
     *********************************************************/
    fn_Init() {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      this.fn_SelCntnt()
    },
    /*********************************************************
     * Function명: fn_SelCntnt
     * 설명: 면담기록부 입력값 조회
     *********************************************************/
    fn_SelCntnt() {
      const lv_Vm = this

      let pParams = {
        traineEno : this.pData450M.traineEno, // '00001234', // this.pData450M.traineEno
        EncrsAttrCd: 'F040',
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr
      }
      let trnstId = 'txTSSRC70S3'
      let auth = 'S'

      // 초기화
      this.isNoData = false

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            // console.log(response.body)
            let result = response.body
            lv_Vm.invwCntntList = result.invwCntntList
            
            if (!_.isEmpty(result)) {
              lv_Vm.fn_SetInvwCntnt(result)
            } else {
              lv_Vm.isNoData = true
            }
            lv_Vm.isNoData = false
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SetInvwCntnt
     * 설명: 면담기록부 답변값 셋팅
     *********************************************************/
    fn_SetInvwCntnt(rData) {
      const lv_Vm = this
      let rtnList = []
      // {idx:1,  questCd:'001', ctgeTitle:'0차월과정', title:'교육참석률'},
      let invwItem = rData.invwCntntList
      invwItem.forEach(item => {
        for (let i in lv_Vm.questList) {
          if (item.invwQuestCd === lv_Vm.questList[i].questCd) {
            let currItem = lv_Vm.questList[i]
            rtnList.push({idx: i, invwQuestCd:item.invwQuestCd, ctgeTitle: currItem.ctgeTitle, title: currItem.title, invwAnswNo:item.invwAnswNo, addAnswCntnt: item.addmAnswCntnt})
            if (item.invwQuestCd === '014') {
              this.prspCust = item.addmAnswCntnt
            }
            return
          }
        }
      })

      // 교육지점장 종합면담의견 셋팅
      this.obsOpin = this.obsOpinList.filter(item=>{
        return item.value === rData.obsOpinCd
      })
      this.obsOpinTxt = this.obsOpin[0].text
      
      // invwQuestCd 기준 정렬(오름차순)
      rtnList = rtnList.sort(function(a,b) {
        return a.invwQuestCd - b.invwQuestCd
      })

      this.lv_InvwCtgeList = this.fn_SetClfyCtge(rtnList)
      this.lv_InvwCntntList = rtnList
    },
    /*********************************************************
     * Function명: fn_SetClfyCtge
     * 설명: 답변지 카테고리별 셋팅
     *********************************************************/
    fn_SetClfyCtge(rData) {
      let rtnList = []
      let dataList1 = []
      let dataList2 = []
      let dataList3 = []

      dataList1.title = '경력'
      dataList2.title = '회사설명'
      dataList3.title = '속성'
      
      rData.forEach(item => {
        if (Number(item.invwQuestCd)<= 4) { // 경력
          dataList1.push(item)
        } else if (Number(item.invwQuestCd)<= 6) { // 회사설명
          dataList2.push(item)
        } else if (Number(item.invwQuestCd)<= 11) { // 속성
          dataList3.push(item)
        }
      })

      rtnList = [dataList1, dataList2, dataList3]
      // console.log('카테고리 분류 LIST', rtnList)
      return rtnList
    }
  }
}
</script>

/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC481P
 * 화면 설명: CDF관리 화면 - 유경력 면담기록부 작성
 * 화면 접근권한: [작성]CA, 교육지점장
 * 작 성 일: 2022.07.15
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="유경력 면담기록부 작성" type="popup" @before-close="fn_ConfmModalOpen">
    <ur-box-container direction="row" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box gray">
        <div class="row left-right">
          <div class="title">
            <span>{{ traineNm }}({{ traineEno }})</span>
          </div>
          <div class="value">
            <span>{{ fofNm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>입과월</span>
          </div>
          <div class="value">
            <span>{{ encrsYm }}</span>
          </div>
        </div>
      </ur-box-container>

      <mo-pagination componentid="mo_pagination_001" simple :pagination="mo_pagination" @move="onMove" class="ns-pagination mtb30"/>
      <mo-carousel componentid="mo_carousel_001" :no-rotate="true" :number-of-page="numberOfPage" ref="carousel" height="315" :next-button="false" :prev-button="false"
                  @on-change="onQuestionChange" :swiper-option="swiperOption" class="ns-carousel no-pagination" style="padding:0 24px;">
        <template v-for="qItem in questList" v-slot:[`${qItem.idx}`]>
          <div :key="qItem.questCd" style="background:white; height:100%; width:100%" class="ns-swiper-box">
              <span class="ns-swiper-title">{{ qItem.ctgeTitle }}</span>
            <div class="ns-banner-box">
              <strong class="title">{{ qItem.title }}</strong>
              <div class="ns-radio-list" :class="qItem.isAddClass? 'w100' : ''">
                <mo-radio-wrapper v-model="qItem.answValu" @input="fn_NextInvw(qItem)">
                  <mo-radio v-for="aItem in qItem.answList" :key="aItem.value" :value="aItem.value">{{ aItem.text }}</mo-radio>
                </mo-radio-wrapper>
                <!-- 타사이력 기간 추가문항 ::start -->
                <div>
                  <mo-radio-wrapper v-show="qItem.questCd === '003' && isShowTasa" v-model="tasaQuest[0].answValu" @input="fn_NextInvw(tasaQuest[0])">
                    <mo-radio v-for="tasaItem in tasaAnsw" :key="tasaItem.value" :value="tasaItem.value">{{ tasaItem.text }}</mo-radio>
                  </mo-radio-wrapper>
                </div>
                <!-- 타사이력 기간 추가문항 ::end -->
              </div>
            </div>
          </div>
        </template>
        <template #pagination>
          <!-- <mo-icon>home</mo-icon> -->
        </template>
      </mo-carousel>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="isFirst" @click="fn_SaveCntnt">임시저장</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!isAllChk" @click="fn_SubmCntnt">제출</mo-button>
      </div>
    </ur-box-container>
    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
    <!-- Close Confirm Modal ::start -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refConfirmModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            작성중인 화면에서 나가시겠습니까?<br/>
            나가기 선택 시 작성된 내용은 저장되지 않습니다.
          </p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_ConfmModalClose('N')">아니오</mo-button>
                <mo-button class="ns-btn-round blue" @click="closeFn">예</mo-button>
              </div>
            </ur-box-container>
          </template>
      </mo-bottom-sheet>
    </div>
    <!-- Close Confirm Modal ::end -->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  name: 'MSPRC481P',
  screenId: 'MSPRC481P',
  mixins:[Screen],
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right', // 우->좌 open popup (defalut: 하->상)
    beforeClose: true // 타이틀영역 팝업닫기 icon 클릭시 실행
  },
  props: {
    pData450M: {
      type: Object
    }
  },
  data() {
    return {
      title: '',
      isFirst: true,
      isShowTasa: false, // 타사 근무기간 보여줄지 여부
      isAllChk: false, // 모든 입력값 체크 여부
      traineInqrList: [], // response
      encrsAttrCd: 'F040', // 입과 속성 코드

      /** 유경력 질문지 **/
      // questCd: 임의 수정 금지, 관리자 페이지 엑셀 다운로드 영향이 있습니다. questCd 코드: 테이블 정의서(TTSR00022) 참조
      // 교육지점장 종합면담의견 문항은 정의된 questCd 코드가 없음.
      questList: [
        {idx:1,  encrsAttrCd:'F040', questCd:'002', answValu:'', ctgeTitle:'경력',     title:'당사이력'},
        {idx:2,  encrsAttrCd:'F040', questCd:'003', answValu:'', ctgeTitle:'경력',     title:'타사이력'}, // 해당없음이 아니라면 근무기간 추가 입력
        // {idx:2,  encrsAttrCd:'F040', questCd:'004', answValu:'', ctgeTitle:'경력',   title:'타사 근무기간'},
        {idx:3,  encrsAttrCd:'F040', questCd:'005', answValu:'', ctgeTitle:'회사설명',   title:'수수료 제도', isAddClass: true},
        {idx:4,  encrsAttrCd:'F040', questCd:'006', answValu:'', ctgeTitle:'회사설명',   title:'30만 달성 수수료 수준'},
        // // 신용문제 문항 => 전 직장 문항 대체 긴급요청(2022.08.16)
        // {idx:5,  encrsAttrCd:'F040', questCd:'007', answValu:'', ctgeTitle:'속성',       title:'신용문제'},
        {idx:5,  encrsAttrCd:'F040', questCd:'008', answValu:'', ctgeTitle:'속성',     title:'전 직장', isAddCntnt: true, addAnswCntnt: ''},
        {idx:6,  encrsAttrCd:'F040', questCd:'009', answValu:'', ctgeTitle:'속성',     title:'육아문제'},
        {idx:7,  encrsAttrCd:'F040', questCd:'010', answValu:'', ctgeTitle:'속성',     title:'가족반대'},
        {idx:8,  encrsAttrCd:'F040', questCd:'011', answValu:'', ctgeTitle:'속성',     title:'원거리', isAddClass: true},
        {idx:9,  encrsAttrCd:'F040', questCd:'014', answValu:'', ctgeTitle:'잠재고객',   title:''},
        {idx:10, encrsAttrCd:'F040', questCd:'', answValu:'', ctgeTitle:'교육지점장 종합면담의견',  title:''},
      ],

      /** 유경력 답변지 **/
      answList: [
        [{value:'01', text:'1년 ↓'},{value:'02', text:'1년 ↑'},{value:'03', text:'3년 ↑'},{value:'04', text:'5년 ↑'},{value:'05', text:'해당없음'}],
        [{value:'01', text:'생보'},{value:'02', text:'손보'},{value:'03', text:'생손보'},{value:'04', text:'해당없음'}],
        // [{value:'01', text:'1년 ↓'},{value:'02', text:'1년 ↑'},{value:'03', text:'3년 ↑'},{value:'04', text:'5년 ↑'}]
        [{value:'01', text:'정확히 안내 받음'},{value:'02', text:'안내 못 받음'}],
        [{value:'01', text:'월 150만'},{value:'02', text:'월 200만'},{value:'03', text:'월 250만'},{value:'04', text:'월 300만'}],
        // 신용문제 문항 => 전 직장 문항 대체 긴급요청(2022.08.16)
        // [{value:'01', text:'있음'},{value:'02', text:'없음'}], // 신용문제
        [{value:'01', text:'주부'},{value:'02', text:'자영업'},{value:'03', text:'사무직'},{value:'04', text:'보험영업'},{value:'05', text:'교육(학원/유치원)'},{value:'06', text:'서비스직'},{value:'07', text:'투잡'},{value:'08', text:'기타'}],
        [{value:'01', text:'있음'},{value:'02', text:'없음'}], // 육아
        [{value:'01', text:'있음'},{value:'02', text:'없음'}], // 가족반대
        [{value:'01', text:'30분 ↓'},{value:'02', text:'30분~1시간'},{value:'03', text:'1시간 ↑'},{value:'04', text:'1시간 30분 ↑'}],
        [{value:'01', text:'30명 ↓'},{value:'02', text:'30명 ↑'},{value:'03', text:'50명 ↑'},{value:'04', text:'100명 ↑'}],
        [{value:'A', text:'우수'},{value:'B', text:'양호'},{value:'C', text:'보통'},{value:'D', text:'미흡'}]
      ],

      // 타사 근무기간 문항/답변
      tasaQuest: [
        {idx:2,  encrsAttrCd:'F040', questCd:'004', answValu:'', ctgeTitle:'경력', title:'타사 근무기간'} // 해당없음이 아니라면 기간 추가 입력
      ],
      tasaAnsw: [
        {value:'01', text:'1년 ↓'},{value:'02', text:'1년 ↑'},{value:'03', text:'3년 ↑'},{value:'04', text:'5년 ↑'}
      ],
      currentIdx: 0, // 현재 문항 index
      /* 문항 스와이퍼 옵션 */
      swiperOption: {
        centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
      },
      traineNm: '',
      traineEno: '',
      encrsYm: moment(new Date()).format('YYYY-MM'),
      fofNm: '',
      closeFn: '', // X버튼 클릭시 호출 함수

      modalFlag: true // close Modal Flag
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)

    const lv_Vm = this
    this.fn_Init()

    // 문항에 맞는 답변지 셋팅
    this.questList.forEach((item,index) => {
      item.answList = lv_Vm.answList[index]
    })
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    numberOfPage() {
      return this.questList.length;
    },
    mo_pagination() {
      return {
        rowsPerPage: 1,
        currentPage: this.currentIdx + 1,
        totalPages: 10,
        totalItems: this.questList.length
      };
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 화면 진입시, 최초실행 함수
     *********************************************************/
    fn_Init() {
      // console.log('pData450M', this.pData450M)
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보

      this.traineNm = this.pData450M.traineNm
      this.traineEno = this.pData450M.traineEno
      this.encrsYm = moment(this.pData450M.encrsYm).format('YYYY-MM')
      this.fofNm = this.pData450M.traineFofOrgNm

      if (this.pData450M.wrtCmpltYn === "N") {
        this.fn_SelCntnt()
        this.isFirst = false
      } else {
        this.isFirst = true
      }
    },
    /*********************************************************
     * Function명: fn_SelCntnt
     * 설명: 입력값 조회 함수
     *********************************************************/
    fn_SelCntnt() {
      const lv_Vm = this

      let pParams = {
        traineEno : this.pData450M.traineEno,
        encrsAttrCd: this.encrsAttrCd,
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr
      }
      let trnstId = 'txTSSRC70S3'
      let auth = 'S'

      // 초기화
      this.isNoData = false

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            // console.log('임시저장 조회', response.body)
            let result = response.body.invwCntntList
            lv_Vm.invwCntntList = result

            if (!_.isEmpty(result)) {
              // 임시저장 데이터 바인딩
              lv_Vm.fn_SetInvwData(result)
            } else {
              lv_Vm.isNoData = true
            }
            lv_Vm.isNoData = false
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SetInvwData
     * 설명: 입력값 데이터 셋팅
     *********************************************************/
    fn_SetInvwData(result) {
      const lv_Vm = this
      let invwData = _.cloneDeep(result)

      this.questList.forEach(item => {
        for (let i=0; i<invwData.length; i++) {
          if (item.questCd === invwData[i].invwQuestCd) {
            item.answValu = invwData[i].invwAnswNo
          } else if (invwData[i].invwQuestCd === '004') {
            lv_Vm.tasaQuest[0].answValu = invwData[i].invwAnswNo
            lv_Vm.isShowTasa = true
          }
        }
        if (item.questCd === '' && item.idx === 10) {
          item.answValu = lv_Vm.pData450M.obsOpinCd
        }
      })
      let invwEmty = this.questList.filter(item=>{
        return item.answValu === ''
      })
      // 임시저장했을경우 해당 index + 1 로 이동 (저장 안한 문항중 0번째 문항)
      if (invwEmty.length>0) {
        this.$refs.carousel.moveTo(invwEmty[0].idx-1)
      } else {
        this.$refs.carousel.moveTo(invwData.length)
      }

      this.fn_Validation()
    },
    /*********************************************************
     * Function명: fn_SaveCntnt
     * 설명: 임시저장 클릭시, DB 저장후 토스트 안내 메세지
     *********************************************************/
    fn_SaveCntnt() {
      const lv_Vm = this
      let answList = this.fn_GetAnswList()

      let pParams = {
        traineEno   : this.pData450M.traineEno,
        wrtEno: this.userInfo.userId,
        encrsAttrCd: this.encrsAttrCd,
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr,
        serviceType: '01', // 01: save
        obsOpinCd: this.obsOpinCd,
        invwCntntList: answList
      }
      let trnstId = 'txTSSRC70I2'
      let auth = 'S'

      this.isNoData = false

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // console.log(response.body)
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            let result = response.body.invwCntntList
            lv_Vm.invwCntntList = result

            if (!_.isEmpty(result)) {
              lv_Vm.$refs.snackbar.show({ text: '임시저장 되었습니다.' })
              lv_Vm.$emit('onSaveInvwCntnt',{selectItem: lv_Vm.pData450M, sParams:pParams})
            } else {
              lv_Vm.isNoData = true
            }
            lv_Vm.isNoData = false
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SubmCntnt
     * 설명: 제출 클릭시, DB 저장후 팝업 닫기
     *********************************************************/
    fn_SubmCntnt() {
      const lv_Vm = this
      let answList = this.fn_GetAnswList()

      let pParams = {
        traineEno : this.pData450M.traineEno, // '0001845209',
        encrsAttrCd: this.encrsAttrCd,
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr,
        obsOpinCd: this.obsOpinCd,
        serviceType: '02', // 01: save , 02: submit
        invwCntntList: answList
      }
      let trnstId = 'txTSSRC70I2'
      let auth = 'S'

      // 초기화
      this.isNoData = false

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // console.log(response.body)
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            let result = response.body.traineInqrList
            lv_Vm.traineInqrList = result

            lv_Vm.isNoData = false
            lv_Vm.modalFlag = false

            lv_Vm.$emit('onPopupSubmit', {selectItem: lv_Vm.pData450M, sParams:pParams.serviceType})
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_GetAnswList
     * 설명: 문항 답변 선택지 getter
     *********************************************************/
    fn_GetAnswList() {
      const lv_Vm = this
      let rtnList = []

      this.obsOpinCd = " "
      this.questList.forEach((qItem) => {
        let answObj = _.cloneDeep(qItem.answList.filter(aItem => {
          return qItem.answValu === aItem.value
                    }))
        if (_.isEmpty(answObj)) return
        
        if (!lv_Vm.$bizUtil.isEmpty(qItem.questCd)) {
          rtnList.push({invwQuestCd: qItem.questCd, invwAnswNo: answObj[0].value, addmAnswCntnt: answObj[0].text})
        } else {
          lv_Vm.obsOpinCd = qItem.answValu
        }
      })

      // 타사 기간 입력값 push tasaQuest
      let tasaObj = this.tasaAnsw.filter(tasaItem => {
                      if (lv_Vm.tasaQuest[0].answValu === tasaItem.value) {
                        return [{questCd: '004', answValu: tasaItem.value, text: tasaItem.text}]
                      }
                    })
      if (tasaObj.length > 0) {
        rtnList.push({invwQuestCd: '004', invwAnswNo: tasaObj[0].value, addmAnswCntnt: tasaObj[0].text})
      } else {
        rtnList.push({invwQuestCd: '004', invwAnswNo: ' ', addmAnswCntnt: ' '})
      }
      
      // console.log(rtnList)
      return rtnList
      
    },
    /*********************************************************
     * Function명: fn_NextInvw
     * 설명: 답변 선택시, 슬라이드
     *       003 문항의 기타 선택시 슬라이드 pass
     *********************************************************/
    fn_NextInvw(item) {
      if (this.isFirst) this.isFirst = false

      if (item.questCd === '003') {
        if (item.answValu !== '04') {
          this.isShowTasa = true
          return
        } else {
          this.tasaQuest[0].answValu = ''
          this.isShowTasa = false
        }
      }

      this.$refs.carousel.smoothMoveTo(item.idx)

      this.fn_Validation()
      // console.log('Click Item',item)
    },
    /*********************************************************
     * Function명: fn_Validation
     * 설명: 모든 문항 답변했는지 여부
     *********************************************************/
    fn_Validation() {
      let rtnCnt = 0

      this.questList.forEach(item => {
        if (item.answValu !== '') {
          rtnCnt += 1
        } else {
          rtnCnt -= 1
        }
      })

      if (rtnCnt === this.questList.length) {
        this.isAllChk = true
      } else {
        this.isAllChk = false
      }
    },
    /******************************************************************************
     * Function명 : fn_ConfmModalOpen / fn_ConfmModalClose
     * 설명       : 작성중 팝업닫을 시 안내 Modal Open/Close
     ******************************************************************************/
    fn_ConfmModalOpen(closeFn) {
      this.closeFn = closeFn // before-close 함수 실행
      if (!this.modalFlag) { // 제출시에만, PopUp Close 함수 실행
        closeFn()
        return
      }
      this.$refs.refConfirmModal.open()
    },
    fn_ConfmModalClose() { this.$refs.refConfirmModal.close() },
    /*********************************************************
     * 설명: onQuestionChange / onMove 
     *        <mo-carousel> 태그에 필요 함수
     *********************************************************/
    onQuestionChange(idx) {
      this.currentIdx = idx;
    },
    onMove(idx) {
      this.$refs.carousel.smoothMoveTo(idx - 1);
    }
  }
}
</script>

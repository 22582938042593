/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC450M
 * 화면 설명: 0차월 관찰표 - CDF폴더(이전) 화면
 * 화면 접근권한: CA, 지점장, 지역단스텝, 리크루팅본사스텝 (F002, F110, F102, R100)
 * 작성/조회 권한: - 0차월  [작성]CA                   / [조회]지역단스텝(단장, 파트장, 지점장),CA
 *                - 유경력 [작성]교육지점장(지역단스텝) / [조회]지역단스텝(단장, 파트장, 지점장),CA
 * 작 성 일: 2022.07.05
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" :title="title">
    <ur-box-container direction="column" alignV="start">
        <!-- 상단고정 Tab UI -->
        <div class="ns-sliding-topbar">
          <mo-collapsing-header-view headerColor="#fff">

          <template #fixed="{scrollRate}">
            <!-- 헤더 타이틀 영역 ::start -->
              <mo-top-bar color="primary">
                <div slot="nav" class="icon-wrapper">
                  <mo-icon icon-size="16px" borderless @click="fn_HistoryBack">previous</mo-icon>
                </div>

                <!-- 헤더 타이틀 영역 -->
                {{scrollRate > 0.5 ? '0차월 관찰표' : '0차월 관찰표'}}

                <div slot="action" class="icon-wrapper">
                  <mo-icon icon-size="16px" type="picto" @click="fn_OpenPop">search</mo-icon>
                </div>
                <!-- 헤더 타이틀 영역 //-->
              </mo-top-bar>
          </template>
          
          <!-- collapsing 영역 -->
          <template #collapsing>
            <div></div>
          </template>
          
          <!-- stickey 영역 -->
          <!-- <template #sticky>
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-tab-box">
              <mo-tab-box default-idx="01">
                  <mo-tab-label idx="01" @click="fn_TabClick('01')">0차월</mo-tab-label>
                  <mo-tab-label idx="02" @click="fn_TabClick('02')">유경력</mo-tab-label>
              </mo-tab-box>
            </ur-box-container>
          </template> -->
 
          <template #scroll>
              <!-- 0차월 content -->
              <MSPRC460D ref="refRC460D" v-show="sleEncrsAttrCd === 'F990'" 
                                          :pEncrsYm="encrsYm" :traineInqrListVO1="traineInqrListVO1" :traineInqrList1="traineInqrList1"
                                          @onChangeMonth="fn_changeMonth"/>
              <!-- 유경력 content -->
              <!-- <MSPRC480D ref="refRC480D" v-show="sleEncrsAttrCd === 'F040'"
                                          :pEncrsYm="encrsYm" :traineInqrListVO2="traineInqrListVO2" :traineInqrList2="traineInqrList2"
                                          @onChangeMonth="fn_changeMonth"/> -->
          </template>

        </mo-collapsing-header-view>    
      </div>
      <!-- 상단고정 Tab UI //-->
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPRC460D from '@/ui/rc/MSPRC460D.vue'
import MSPRC480D from '@/ui/rc/MSPRC480D.vue'
import MSPRC451P from '@/ui/rc/MSPRC451P.vue'

export default {
  name: 'MSPRC450M',
  screenId: 'MSPRC450M',
  mixins:[Screen],
  components: {
    MSPRC451P, // 상세조회
    MSPRC460D, // 0차월
    MSPRC480D  // 유경력
  },
  data() {
    return {
      title: '0차월 관찰표',
      sleEncrsAttrCd: 'F990', // Default: 0차월 (0차월: F990, 유경력: F040)

      traineInqrListVO1: {}, // 0차월 response
      traineInqrList1: [],
      traineInqrListVO2: {}, // 유경력 response
      traineInqrList2: [],
      tabIdx: '01',

      pOrgData450M: {}, // 최근 조직 조회 데이터 
      isSearched: false, // 조회이력 저장 변수

      encrsYm: '', // 입과월 (검색용)
      pTraineNm: '' // 교육생명 (검색용)
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    const lv_Vm = this
    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)

    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      this.encrsYm = moment(new Date()).format('YYYYMM')
      // console.log('userInfo', this.userInfo)
      // 교육생 목록 조회
      this.fn_TraineList()
    },
    /******************************************************************************
     * Function명 : fn_TabClick
     * 설명       : 결과입력/결과조회 탭 클릭 이벤트
     * ****************************************************************************/
    fn_TabClick(tabIdx) {
      this.tabIdx = tabIdx
      this.pTraineNm = '' // 교육생 이름(검색용) 초기화

      if (tabIdx === '01') {
        this.sleEncrsAttrCd = 'F990'
        if (_.isEmpty(this.traineInqrListVO1) ||
            this.$refs.refRC460D.encrsYm !== moment(this.encrsYm).format('YYYY-MM')) {
          this.fn_TraineList()
        }
      } else if (tabIdx === '02') {
        this.sleEncrsAttrCd = 'F040'
        if (_.isEmpty(this.traineInqrListVO2) ||
            this.$refs.refRC480D.encrsYm !== moment(this.encrsYm).format('YYYY-MM')) {
          this.fn_TraineList()
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_TraineList
     * 설명       : 교육생 목록 조회
     * ****************************************************************************/
    fn_TraineList() {
      const lv_Vm = this

      let getParam = this.fn_SetParam() // SVO 셋팅

      let pParams = {
        cnsltEno: this.userInfo.cnsltNo, // 사용자 사번
        traineNm: this.pTraineNm, // 교육생명,
        traineHofOrgNo   : getParam.traineHofOrgNo, // 선택사업부,
        traineDofOrgNo   : getParam.traineDofOrgNo, // 선택지역단,
        traineFofOrgNo   : getParam.traineFofOrgNo, // 선택지점,
        encrsAttrCd: this.sleEncrsAttrCd, // 0차월: F990/ 1차월: F991/ 유경력: F040
        encrsYm: getParam.encrsYm // 입과월
      }
      let trnstId = 'txTSSRC70S1'
      let auth = 'S'

      // console.log('pParams>', pParams)
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body)) {
            // console.log(response.body)
            let result = response.body
            if (lv_Vm.sleEncrsAttrCd === 'F990') {
              lv_Vm.traineInqrListVO1 = result
              lv_Vm.traineInqrList1 = result.traineInqrList
            } else if (lv_Vm.sleEncrsAttrCd === 'F040') {
              lv_Vm.traineInqrListVO2 = result
              lv_Vm.traineInqrList2 = result.traineInqrList
            }
            lv_Vm.encrsYm = result.encrsYm // 입과월
            lv_Vm.isNoData = false
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /******************************************************************************
     * Function명 : fn_changeMonth
     * 설명       : 입과월 변경시 호출 함수
     *              교육생 조회 간편기능(입과월 변경시 선택조직 그대로 월만 변경)
     * ****************************************************************************/
    fn_changeMonth(pData) {
      // this.pTraineNm = '' // 교육생 이름(검색용) 초기화 / 입과월 변경시 초기화 필요시 주석 풀기
      this.encrsYm = pData
      this.fn_TraineList()
    },
    /******************************************************************************
     * Function명 : fn_SetParam
     * 설명       : SVO 셋팅
     *              교육생 목록 조회 파라미터 셋팅
     * ****************************************************************************/
    fn_SetParam() {
      let rtnObj = {}

      // 상세조회에서 검색했을때
      if (!_.isEmpty(this.pOrgData450M) && !_.isEmpty(this.pOrgData450M.fofOrg)) {
        rtnObj.traineDofOrgNo = this.pOrgData450M.dofOrg.key
        rtnObj.traineHofOrgNo = this.pOrgData450M.hofOrg.key
        rtnObj.traineFofOrgNo = this.pOrgData450M.fofOrg.key
        rtnObj.encrsYm = this.encrsYm
      } else { // 아니라면, 사용자 정보로 셋팅
        let fofOrgNo = ''
        // 초기조회 CA라면 모든 지점으로 조회
        if (this.userInfo.userRoleList.indexOf('F002') > -1 || this.userInfo.userRoleList.indexOf('F102') > -1) {
          fofOrgNo = '0'
        } else {
          fofOrgNo = this.userInfo.onpstFofOrgNo
        }
        rtnObj.traineHofOrgNo = this.userInfo.onpstHofOrgNo  // 선택사업부
        rtnObj.traineDofOrgNo = this.userInfo.onpstDofNo     // 선택지역단
        rtnObj.traineFofOrgNo = fofOrgNo
        rtnObj.encrsYm = this.encrsYm
      }

      return rtnObj
    },
    /******************************************************************************
     * Function명 : fn_OpenPop
     * 설명       : 상세조회 팝업 호출 함수
     ******************************************************************************/
    fn_OpenPop() {
      const lv_Vm = this

      this.popup451 = this.$bottomModal.open(MSPRC451P,{
        properties: {
          title: '상세조회', // 팝업의 타이틀 셋팅
          pTabIdx: this.tabIdx,
          pOrgData450M: this.pOrgData450M, // 최근 조직 조회 데이터
          pEncrsYmStr: this.encrsYm, // 입과월(YYYY-MM)
        },
        // 팝업 닫히면서 실행되는 함수
        listeners: {
          // 결과입력에서 조회시 실행
          onPopupSearch: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup451)

            // 조회이력 저장
            lv_Vm.isSearched = true

            lv_Vm.pOrgData450M = pData.pOrgData
            lv_Vm.encrsYm = pData.encrsYmStr
            lv_Vm.pTraineNm = pData.traineNm
            // 교육생 목록 조회
            lv_Vm.fn_TraineList()
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 이전 화면으로 전환
     ******************************************************************************/
    fn_HistoryBack() {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.pOrgData450M = {}
        this.pTraineNm = '' 
        this.isSearched = false // 검색이력 초기화

        this.fn_Init()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    }
  }
}
</script>

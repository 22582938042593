/*
* 업무구분 : 리크루팅
* 화면 명  : SearchDetail
* 화면 설명: 리크루팅 CDF폴더 상세조회 컴포넌트
* 기존 SearchDetail 변형
*  ㆍ 컨설턴트 삭제
*  ㆍ 화면 파라미터 中 isFofChng 으로, 지점 비활성화 컨트롤 추가(2022.08.04)
*/
<template>
  <div style="width:100%;">
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="sch-box">
      <div class="drop-box">
        <msp-bottom-select class="ns-dropdown-sheet" placeholder="조직" bottom-title="조직" closeBtn
          :itemHeight="374" v-model="lv_HofOrg.key" :itemValue="'key'" :itemText="'label'"
          :items="lv_HofOrgData" @input="fn_GetDofData" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" underline/>
        <msp-bottom-select class="ns-dropdown-sheet" placeholder="지역단" bottom-title="지역단" closeBtn
          :itemHeight="374" v-model="lv_DofOrg.key" :itemValue="'key'" :itemText="'label'"
          :items="lv_DofOrgData" @input="fn_GetFofData" :disabled="lv_OrgAuth.isHofRolYn==='N'" underline/>
        <msp-bottom-select class="ns-dropdown-sheet" placeholder="지점" bottom-title="지점" closeBtn
          :itemHeight="374" v-model="lv_FofOrg.key" :itemValue="'key'" :itemText="'label'"
          :items="lv_FofOrgData" @input="fn_SetReturnVal" :disabled="lv_OrgAuth.isDofRolYn==='N' && !isFofChng" v-if="isShowFofOrg" underline/>
      </div>
    </ur-box-container>
  </div>
</template>

<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'

export default{
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  ***********************************************************************************/

  name: 'SearchDetail',
  screenId: 'SearchDetail',
  components: {MspBottomSelect},
  props: {
    'reqObject': Object,
    'isShowFofOrg': {
      type: Boolean,
      default: true
    },
    'isFofType': {
      type: Number,
      default: 0
    },
    'isFofChng': {
      type: Boolean,
      default: false,
      name: '선택지점 변경 가능'
    },
    'pOrgData': Object
  },

  /***********************************************************************************
  * 화면 UI Data Field 정의
  ***********************************************************************************/

  data () {
    return {
      userInfo: {}, // 사용자 정보

      lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
      lv_HofOrg: {key: '00000000', label: '선택'}, // 선택사업부
      lv_DofOrg: {key: '0', label: '선택'}, // 선택지역단
      lv_FofOrg: {key: '0', label: '선택'}, // 선택지점
      lv_HofOrgData: [], // 사업부 목록
      lv_DofOrgData: [], // 지역단 목록
      lv_FofOrgData: [], // 지점 목록
      lv_OrgAuth: [], // 지점권한
      lv_IsFirst: true, // 최초조회 여부
      lv_ReturnVal: {},
      lv_IsAll: false,
      lv_IsParam: false // 조직 파라미터가 존재 여부
    }
  },
  /***********************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  mounted () {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    if ( this.reqObject && this.reqObject.isAll ) {
      this.lv_IsAll = true
    }
    // console.log( this.lv_IsAll )
    this.fn_GetFofRol()
  },
  /***********************************************************************************
  * watch 정의 영역
  ***********************************************************************************/
  updated () {
    this.fn_chkIsParam()
    // lv_IsParam: 조직 파라미터가 존재 할 경우
    if ( !this.lv_IsFirst || this.lv_IsParam ) {
      this.fn_SetReturnVal()
    }
  },
  watch: {
    pOrgData() {
      // 조직 컴포넌트 초기화 요청시
      this.fn_chkIsParam()
    }
  },

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    fn_SetReturnVal () {
      if (this.lv_OrgAuth.isEntpwRolYn === 'N' && this.lv_OrgAuth.isHofRolYn === 'N' && this.lv_OrgAuth.isDofRolYn === 'N') {
        this.disable = true
      } else {
        this.disable = false
      }
      this.lv_ReturnVal = {
        hofOrg: this.lv_HofOrg,
        dofOrg: this.lv_DofOrg,
        fofOrg: this.lv_FofOrg,
        disable: this.disable
      }

      this.$emit('selectResult', this.lv_ReturnVal)
    },
    /******************************************************************************
    * Function명 : fn_GetHofData
    * 설명       : 사업부 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetHofData () {
      if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
      if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
      this.$bizUtil.selListHofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },
    /******************************************************************************
    * Function명 : fn_GetDofData
    * 설명       : 지역단 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetDofData ( param ) {
      this.lv_DofOrg = {key: '0', label: '선택'} // 지역단 초기화
      if ( param ) { // 선택지역단 변경
        this.lv_SearchParam.hofOrgNo = param
        if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        this.lv_DofOrg = {key: '', label: '선택'}
        this.lv_FofOrg = {key: '', label: '선택'}
        if (!_.isEmpty(this.lv_HofOrgData) && this.lv_HofOrg.key !== '0' ) {
          this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofData
    * 설명       : 지점 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofData (param) {
      // 지점 조회 가능한 사용자는 전체로 표시
      if (this.lv_OrgAuth.isDofRolYn === 'Y' && this.isFofType === 1) {
        this.lv_FofOrg = {key: '0', label: '전체'} // 지점 초기화
      } else {
        this.lv_FofOrg = {key: '', label: '선택'} // 지점 초기화
      }
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = param
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        // 지점 조회 가능한 사용자는 전체로 표시
        if (this.lv_OrgAuth.isDofRolYn === 'Y' && this.isFofType === 1) {
          this.lv_FofOrg = {key: '0', label: '전체'} // 지점 초기화
        } else {
          this.lv_FofOrg = {key: '', label: '선택'} // 지점 초기화
        }
      }
      this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },
    /******************************************************************************
    * Function명 : fn_GetFofCnsltData
    * 설명       : 컨설턴트 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofCnsltData (param) {

      // 조직 및 담당컨설턴트 param 존재 할 경우
      if (!_.isEmpty(this.pOrgData) && !_.isEmpty(this.pOrgData.hofOrg)) {
        this.lv_SearchParam.hofOrgNo = this.pOrgData.hofOrg.key
        this.lv_SearchParam.dofOrgNo = this.pOrgData.dofOrg.key
        this.lv_SearchParam.fofOrgNo = this.pOrgData.fofOrg.key
        this.lv_SearchParam.orgNo    = this.pOrgData.fofOrg.key

        if (!_.isEmpty(this.pOrgData.fofOrg) && this.pOrgData.fofOrg.key === '0') {
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd  
        }
      }

      if ( param && !this.lv_IsParam ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        this.lv_SearchParam.fofOrgNo = param
        this.lv_SearchParam.orgNo    = param
      } else {
        this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
        this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
      }
    },
    /******************************************************************************
    * Function명 : fn_LoadingData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_OrgSearchCallBack ( rtnData ) {
      this.lv_HofOrgData = rtnData[0]
      this.lv_DofOrgData = rtnData[1]
      this.lv_FofOrgData = rtnData[2]

      if (this.lv_IsFirst) {
        let isSelHof = false

        // 조직 파라미터가 존재 할 경우
        if (!_.isEmpty(this.pOrgData) && !_.isEmpty(this.pOrgData.hofOrg) && this.lv_IsParam) {
          this.lv_HofOrg = this.pOrgData.hofOrg
          isSelHof = true
        } else {
          let hofOrgNo = ''

          if (this.lv_SearchParam.hofOrgNo === '') {
            hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
          } else {
            hofOrgNo = this.lv_SearchParam.hofOrgNo
          }

          for ( let oData of rtnData[0] ) {
            if ( oData.key === hofOrgNo ) {
              this.lv_HofOrg.key = oData.key;
              this.lv_HofOrg.label = oData.label;
              isSelHof = true
              break
            }
          }
        }

        if (!isSelHof) this.lv_HofOrg = {key: '00000000', label: '선택'}
        let isSelDof = false

        // 조직 파라미터가 존재 할 경우
        if (!_.isEmpty(this.pOrgData) && !_.isEmpty(this.pOrgData.dofOrg) && this.lv_IsParam) {
          this.lv_DofOrg = this.pOrgData.dofOrg
          isSelDof = true
        } else {
          let dofOrgNo = ''

          if (this.lv_SearchParam.hofOrgNo === '') {
            dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
          } else {
            dofOrgNo = this.lv_SearchParam.dofOrgNo
          }

          for ( let oData of rtnData[1] ) {
            if ( oData.key === dofOrgNo ) {
              this.lv_DofOrg.key = oData.key;
              this.lv_DofOrg.label = oData.label;
              isSelDof = true
              break
            }
          }
        }

        if (!isSelDof) this.lv_DofOrg = {key: '0', label: '선택'}
        let isSelFof = false

        // 조직 파라미터가 존재 할 경우
        if (!_.isEmpty(this.pOrgData) && !_.isEmpty(this.pOrgData.fofOrg) && this.lv_IsParam) {
          this.lv_FofOrg = this.pOrgData.fofOrg
          isSelFof = true
        } else {
          let fofOrgNo = ''

          if (this.lv_SearchParam.hofOrgNo === '') {
            fofOrgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd
          } else {
            fofOrgNo = this.lv_SearchParam.fofOrgNo
          }
          if ( (this.userInfo.userRoleList.indexOf('F002') > -1 || this.userInfo.userRoleList.indexOf('F102') > -1) &&
              this.isFofChng ) {
            fofOrgNo = '0'
          }

          for ( let oData of rtnData[2] ) {
            if ( oData.key ===  fofOrgNo) {
              this.lv_FofOrg.key = oData.key;
              this.lv_FofOrg.label = oData.label;
              isSelFof = true
              break
            }
          }
        }

        if (!isSelFof) {
          // 지점 조회 가능한 사용자는 전체로 표시
          if (!this.lv_IsFirst && this.lv_OrgAuth.isDofRolYn === 'Y' && this.isFofType === 1) {
            this.lv_FofOrg = {key: '0', label: '전체'} // 지점 초기화
          } else {
            if (this.lv_IsFirst && this.lv_OrgAuth.isEntpwRolYn !== 'Y' && this.lv_OrgAuth.isDofRolYn === 'Y' && this.isFofType === 1) {
              this.lv_FofOrg = {key: '0', label: '전체'} // 지점 초기화
            } else {
              this.lv_FofOrg = {key: '', label: '선택'} // 지점 초기화
            }
          }
        }
        
        // if (!isSelFof) this.lv_FofOrg = {key: '0', label: '선택'}
        this.fn_GetFofCnsltData(false)
      }
      this.fn_SetReturnVal()
    },
    /******************************************************************************
    * Function명 : fn_SetHofData
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * {isEntpwRolYn[전사역할여부]=Y/N,
    * isHofRolYn[사업부역할여부]=Y/N,
    * isDofRolYn[지역단역할여부]=Y/N,
    * isEofRolYn[지점역할여부]=Y/N}
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_GetFofRolCallBack ( pData ) {
      if ( pData ) {
        this.lv_OrgAuth = pData
        if ( !_.isEmpty(this.pOrgData) && !this.$bizUtil.isEmpty(this.pOrgData.fofOrg) ){
          this.lv_SearchParam.hofOrgNo = this.pOrgData.hofOrg.key
          this.lv_SearchParam.dofOrgNo = this.pOrgData.dofOrg.key
          this.lv_SearchParam.fofOrgNo = this.pOrgData.fofOrg.key
          this.lv_SearchParam.orgNo = this.pOrgData.fofOrg.key

          if (!_.isEmpty(this.pOrgData.fofOrg) && this.pOrgData.fofOrg.key === '0') {
            this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd
            this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd  
          }
        } else {
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.userDeptCd
        }
        this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofRol
    * 설명       : 사용자의 조직조회 권한 확인
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofRol () {
      this.lv_IsFirst = true
      this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
    },
    /******************************************************************************
    * Function명 : fn_chkIsParam
    * 설명       : 호출한 화면에서 파라미터 존재 확인
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_chkIsParam () {
      if ( !_.isEmpty(this.pOrgData) ){
        if (!this.$bizUtil.isEmpty(this.pOrgData.fofOrg)) {
          // 조직 파라미터가 존재 여부
          this.lv_IsParam = true
        } else {
          this.lv_IsParam = false
        }
        this.lv_OrgAuth.isDofRolYn = this.getStore('userInfo').getters.getUserInfo.isDofRolYn
      }
    }
  }

}
</script>

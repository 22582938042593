/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC460D
 * 화면 설명: CDF관리 화면 - 0차월
 * 화면 접근권한: [작성]CA, 교육지점장 / [조회]지역단스텝(단장, 파트장, 지점장)
 * 작 성 일: 2022.07.08
 * 작 성 자: sh.park2022
 */

<template>
  <div>
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-sum padding">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__title">
            <span class="sum flex">총 {{ totLength }}명</span>
            <!-- <span class="month">입과월 <span>{{ encrsYm }}</span></span> -->
            <span class="month">입과월</span>
            <div class="sch-box">
              <div class="ns-date-picker non-border">
                <mo-month-picker v-model="searchDate" placeholder="입과월" :max-date="nowDate" @confirmed="fn_ChangDate"/>
              </div>
            </div>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list2">
      <mo-list-item v-for="item in traineInqrList1" :key="item.traineEno">
        <div class="list-item__contents">
          <div class="item-wrapper__row">
            <div>
              <div class="list-item__contents__title">
                <span class="address">{{ item.traineNm }}</span>
              </div>
              <div class="list-item__contents__info">
                <span>{{ item.traineEno }}</span><em>|</em>
                <span>{{ item.traineFofOrgNm }}</span>
              </div>
              <div class="list-item__contents__info">
                <mo-button v-if="item.wrtCmpltYn !== 'Y'" class="ns-btn-round" @click="fn_Open462P(item)" v-is-enable="{acl: ['I']}">관찰표 작성하기</mo-button>
                <mo-button v-else class="ns-btn-round" @click="fn_Open463P(item)" v-is-enable="{acl: ['S']}">작성완료</mo-button>
              </div>
            </div>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- NoData 영역 ::start  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list2 no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="item-wrapper__row">
            <div>
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- NoData 영역 ::end  -->
  </div>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPRC462P from '@/ui/rc/MSPRC462P.vue'
import MSPRC463P from '@/ui/rc/MSPRC463P.vue'

export default {
  name: 'MSPRC460D',
  screenId: 'MSPRC460D',
  mixins:[Screen],
  components: {
    moment,
    MSPRC462P,
    MSPRC463P
  },
  props: {
    traineInqrList1: {
      type: Array
    },
    traineInqrListVO1: {
      type: Object
    },
    pEncrsYm: {
      type: String
    }
  },
  data() {
    return {
      title: '',
      encrsYm: '', // 입과월
      totLength: 0,
      lv_EncrsAttrCd: 'F990', // 입과월 속성 코드
      isNoData: false,
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      searchDate: []
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  watch: {
    traineInqrList1() {
      if(this.traineInqrListVO1.msgTyp === 'S') {
        this.encrsYm = moment(this.traineInqrListVO1.encrsYm).format('YYYY-MM')
        this.searchDate = this.encrsYm.split('-')
        this.totLength = this.traineInqrList1.length
  
        if (this.traineInqrList1.length > 0) {
          this.isNoData = false
        } else {
          this.isNoData = true
        }
      } else {
        this.isNoData = true
        this.encrsYm = moment(new Date()).format('YYYY-MM')
      }
    }
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보

      this.searchDate.push(new Date().getFullYear().toString())
      let tempMonth = (new Date().getMonth() + 1).toString()
      if(tempMonth.length === 1){
        tempMonth = '0' + tempMonth
      }
      this.searchDate.push(tempMonth)
    },
    /******************************************************************************
     * Function명 : fn_ChangDate
     * 설명       : 입과월 변경시, 교육생 조회 함수 호출
     *               현재 셋팅된 조직 데이터 그대로 월만 변경하여 조회한다
     ******************************************************************************/
    fn_ChangDate(param) {
      param = moment(param).format('YYYYMM')
      // console.log('param', param)
      this.$emit('onChangeMonth', param)
    },
    /******************************************************************************
     * Function명 : fn_Open462P
     * 설명       : 0차월 관찰표 작성 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_Open462P(item) {
      const lv_Vm = this

      this.popup462 = this.$bottomModal.open(MSPRC462P,{
        properties: {
          title: '0차월 관찰표 작성', // 팝업의 타이틀 셋팅
          encrsAttrCd: this.lv_EncrsAttrCd, // 입과 속성 코드 (0차월: F990)
          pData450M: item
        },
        listeners: {
          // 제출
          onPopupSubmit: (pData) => {
            this.$bottomModal.close(lv_Vm.popup462)
            lv_Vm.fn_ResetData(pData)
          },
          // 임시저장
          onSaveInvwCntnt: (pData) => {
            lv_Vm.fn_ResetData(pData)
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_Open463P
     * 설명       : 0차월 관찰표 확인 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_Open463P(item) {
      this.popup463 = this.$bottomModal.open(MSPRC463P,{
        properties: {
          title: '0차월 관찰표 보기', // 팝업의 타이틀 셋팅
          pData450M: item
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_ResetData
     * 설명       : 임시저장/제출한 req Data로 setting
     ******************************************************************************/
    fn_ResetData(pData) {
      const lv_Vm = this

      this.traineInqrList1.forEach(item=>{
        if (item.traineEno === pData.selectItem.traineEno) {
          item.wrtNm = lv_Vm.userInfo.userNm
          item.wrtEno = lv_Vm.userInfo.userId
          item.obsOpinCd =  pData.sParams.obsOpinCd // 평가 코드
          item.wrtCmpltYn = pData.sParams !== '02' ? 'N' : 'Y' // 01: 임시저장, 02: 제출
        }
      })
    }
  }
}
</script>

/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPRC451P
 * 화면 설명: CDF관리 - 상세조회
 * 작 성 일: 2022.07.05
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container title="상세조회" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">
        <span class="sch-title">{{ pTabIdx === '01' ? '0차월' : '유경력' }}</span>
        <!-- 교육생 이름 검색 -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box">
          <ur-text componentid="span_common_text_001" class="title">이름</ur-text>
          <mo-text-field v-model="searchKeyword" underline placeholder="교육생 이름을 입력하세요." maxlength="10" class="form-input-name"/>
        </ur-box-container>

        <!-- 입과월 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="sch-box">
          <div class="ns-date-picker">
            <mo-month-picker v-model ="encrsYm" @confirmed="fn_ChangDate" :max-date="nowDate" placeholder="입과월"/>
          </div>
        </ur-box-container>

        <!-- 조직관련 COMPONENT -->
        <SearchDetailCDF ref="refSearchOrg" v-model="lv_SelectOrg" 
                        :isFofType="1" :pOrgData="pOrgData451P" :isFofChng="isFofChng"
                        @selectResult="fn_SlectCnsltResultRC"/>

        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close" :disabled="!allChk">조회</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Screen from '~systems/mixin/screen'
  import moment from 'moment'
  import SearchDetailCDF from '@/ui/rc/SearchDetailCDF.vue'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPRC451P',
    screenId: 'MSPRC451P',
    mixins: [Screen],
    components: {
      SearchDetailCDF
    },
    props: {
      title: {
        type: String,
        default: '상세조회'
      },
      pEncrsYmStr: {
        type: String,
        name: '세미나 실시월' // format: YYYYMM
      },
      pTabIdx: {
        type: String,
        default: '01', // 01: 0차월 / 02: 유경력
      },
      pOrgData450M: {
        type: Object,
        name: '최근검색 조직'
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_ReturnVal: {},

        nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘

        encrsYm: '',    // 실시월(Array)
        encrsYmStr: '', // 실시월(String)

        // -- 조직 컴포넌트 -- // 
        lv_SelectOrg: [], // 데이터

        pOrgData451P: {},
        allChk: false, // 조직 조회 완료 여부
        searchKeyword: ''
      }
    },
    /************************************************************************************************
     * Life Cycle 함수 정의 영역
     ************************************************************************************************/
    created() {
      // 사용자 정보
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      this.pOrgData451P = this.pOrgData450M
      this.fn_Init()
    },
    mounted() {
      /** LOG 적재 Util **/
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 최초 로딩 시 수행되는 함수
       ******************************************************************************/
      fn_Init() {
        // console.log('userInfo', this.userInfo)
        // 0차월 (CA, 교육지점장은 지점 선택 가능: 예외적 케이스 [현업요청 08.03])
        if (this.userInfo.userRoleList.indexOf('F002') > -1 || this.userInfo.userRoleList.indexOf('F102') > -1) {
          this.isFofChng = true
        } else {
          this.isFofChng = false
        }
        this.fn_GetInitDate()
      },
      /******************************************************************************
       * Function명 : fn_ChangDate
       * 설명       : 달력컴포넌트 변경시
       ******************************************************************************/
      fn_ChangDate(param) {
        if (this.encrsYmStr !== moment(param).format('YYYYMM')) {
          this.encrsYmStr = moment(param).format('YYYYMM')
        }
      },
      /******************************************************************************
       * Function명 : fn_GetInitDate
       * 설명       : 초기 날짜 설정
       ******************************************************************************/
      fn_GetInitDate(isClear) {
        if (_.isEmpty(this.pEncrsYmStr) || isClear) { // isClear: 초기화 눌렀을때
          this.encrsYm = moment(new Date()).format('YYYY-MM').split('-')
          this.encrsYmStr = this.encrsYm[0]+this.encrsYm[1]
        } else {
          this.encrsYmStr = this.pEncrsYmStr.substring(0,4)+this.pEncrsYmStr.substring(4,6)
          this.encrsYm = [this.pEncrsYmStr.substring(0,4),this.pEncrsYmStr.substring(4,6)]
        }
      },
      /******************************************************************************
       * Function명 : fn_SlectCnsltResultRC
       * 설명       : 조직 변경시, 전역변수에 셋팅
       *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
       * allChk: 조회버튼 활성화/비활성화 여부
       ******************************************************************************/
      fn_SlectCnsltResultRC(pData) {
        this.lv_SelectOrg = pData
        if (!_.isEmpty(pData)) {
          this.allChk = true
        } else {
          this.allChk = false
        }
        // console.log('pData', pData)
      },
      /******************************************************************************
       * Function명 : fn_SetReturnVal
       * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
       ******************************************************************************/
      fn_SetReturnVal() {
        if (_.isEmpty(this.lv_SelectOrg)) return

        let fofOrgCd = ''
        if (this.lv_SelectOrg.fofOrg.key !== '0') {
          fofOrgCd = this.lv_SelectOrg.fofOrg.key
        }

        this.lv_ReturnVal = {
          hofOrgCd: this.lv_SelectOrg.hofOrg.key,  // 선택사업부
          dofOrgCd: this.lv_SelectOrg.dofOrg.key,  // 선택지역단
          fofOrgCd: fofOrgCd,                      // 선택지점
          pOrgData: this.lv_SelectOrg,             // 조회 조직 데이터
          traineNm: this.searchKeyword.trim(),
          encrsYmStr: this.encrsYmStr
        }
      },
      /******************************************************************************
       * Function명 : fn_Clear
       * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
       ******************************************************************************/
      fn_Clear() {
        this.pOrgData451P = {}
        this.$refs.refSearchOrg.fn_GetFofRol() // 초기화
        this.fn_GetInitDate(true) // 실시월 초기화
        this.searchKeyword = ''
      },
      /******************************************************************************
       * Function명 : fn_Close
       * 설명       : 팝업 닫기
       ******************************************************************************/
      fn_Close() {
        this.fn_SetReturnVal()
        this.$emit('onPopupSearch', this.lv_ReturnVal)
      }
    }
  }
</script>

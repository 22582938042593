/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC460D
 * 화면 설명: CDF관리 화면 - 0차월 속성관찰표 작성
 * 화면 접근권한: [작성]CA, 교육지점장
 * 작 성 일: 2022.07.12
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="0차월 관찰표 작성" type="popup" ref="questPopup" @before-close="fn_ClosePopup">
    <ur-box-container direction="row" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box gray">
        <div class="row left-right">
          <div class="title">
            <span>{{ traineNm }}({{ traineEno }})</span>
          </div>
          <div class="value">
            <span>{{ fofNm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>입과월</span>
          </div>
          <div class="value">
            <span>{{ encrsYm }}</span>
          </div>
        </div>
      </ur-box-container>

      <mo-pagination componentid="mo_pagination_001" simple :pagination="mo_pagination" @move="onMove" class="ns-pagination mtb30"/>
      <mo-carousel componentid="mo_carousel_001" :no-rotate="true" :number-of-page="numberOfPage" ref="carousel" height="auto" :next-button="false" :prev-button="false"
                  @on-change="onQuestionChange" :swiper-option="swiperOption" class="ns-carousel no-pagination" style="padding:0 24px;">
        <template v-for="qItem in questList" v-slot:[`${qItem.idx}`]>
          <div :key="qItem.idx" style="background:white; height:100%; width:100%" class="ns-swiper-box" :class="isTxtFocus? 'mgn-bottom-100': ''">
              <span class="ns-swiper-title">{{ qItem.ctgeTitle }}</span>
            <div class="ns-banner-box">
              <strong class="title">{{ qItem.title }}</strong>
              <div class="ns-radio-list" :class="qItem.isAddClass ? 'w100' : ''">
                <mo-radio-wrapper v-model="qItem.answValu" @input="fn_NextInvw(qItem)">
                  <mo-radio v-for="aItem in qItem.answList" :key="aItem.value" :value="aItem.value">{{ aItem.text }}</mo-radio>
                </mo-radio-wrapper>
                <div class="text-area-box">
                  <mo-text-area v-model="jobAddCntnt" v-if="qItem.questCd === '008'" placeholder="최대 50자까지 입력가능합니다." :maxlength="50" :disabled="qItem.answValu !== '08'"
                                @focus="fn_TxtFocus" @blur="fn_TxtFocusOut"/>
                  <mo-text-area v-model="caOpinAddCntnt" v-else-if="qItem.questCd === '016'" placeholder="최대 90자까지 입력가능합니다." :maxlength="90" />
                </div>
              </div>
            </div>
          </div>
        </template>
        
        <template #pagination>
          <!-- <mo-icon>home</mo-icon> -->
        </template>
      </mo-carousel>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="isFirst" @click="fn_SaveCntnt">임시저장</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!isAllChk" @click="fn_SubmCntnt">제출</mo-button>
      </div>
    </ur-box-container>
    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
    <!-- Close Confirm Modal ::start -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refConfirmModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            작성중인 화면에서 나가시겠습니까?<br/>
            나가기 선택 시 작성된 내용은 저장되지 않습니다.
          </p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_ConfmModalClose('N')">아니오</mo-button>
                <mo-button class="ns-btn-round blue" @click="closeFn">예</mo-button>
              </div>
            </ur-box-container>
          </template>
      </mo-bottom-sheet>
    </div>
    <!-- Close Confirm Modal ::end -->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
/***********************************************************************************
 * Vue 파일 속성 정의 영역	                                                       *
 ***********************************************************************************/
  name: 'MSPRC462P',
  screenId: 'MSPRC462P',
  mixins:[Screen],
  components: {
    moment
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right', // 우->좌 open popup (defalut: 하->상)
    beforeClose: true // 타이틀영역 팝업닫기 icon 클릭시 실행
  },
  props: {
    pEncrsAttrCd: {
      type: String,
      default: 'F990'
    },
    pData450M: {
      type: Object
    }
  },
  data() {
    return {
      jobAddCntnt: '', // 전직장 문항의 기타 입력
      caOpinAddCntnt: '', // CA의견 문항 입력
      isFirst: true,
      isAllChk: false,
      /** 0차월 질문지 **/
      // questCd: 임의 수정 금지, 관리자 페이지 엑셀 다운로드 영향이 있습니다. questCd 코드: 테이블 정의서(TTSR00022) 참조
      // CA 종합 평가 문항은 정의된 questCd 코드가 없음.
      questList: [
        {idx:1,  encrsAttrCd:'F990', questCd:'001', answValu:'', ctgeTitle:'0차월과정', title:'교육참석률'},
        {idx:2,  encrsAttrCd:'F990', questCd:'012', answValu:'', ctgeTitle:'0차월과정', title:'Job 수용성', isAddClass: true,},
        {idx:3,  encrsAttrCd:'F990', questCd:'013', answValu:'', ctgeTitle:'0차월과정', title:'교과목 이해도', isAddClass: true,},
        {idx:4,  encrsAttrCd:'F990', questCd:'007', answValu:'', ctgeTitle:'속성',      title:'신용문제'},
        {idx:5,  encrsAttrCd:'F990', questCd:'008', answValu:'', ctgeTitle:'속성',      title:'전 직장', isAddCntnt: true, addAnswCntnt: ''},
        {idx:6,  encrsAttrCd:'F990', questCd:'009', answValu:'', ctgeTitle:'속성',      title:'육아문제'},
        {idx:7,  encrsAttrCd:'F990', questCd:'010', answValu:'', ctgeTitle:'속성',      title:'가족반대'},
        {idx:8,  encrsAttrCd:'F990', questCd:'011', answValu:'', ctgeTitle:'속성',      title:'원거리'},
        {idx:9,  encrsAttrCd:'F990', questCd:'015', answValu:'', ctgeTitle:'총평', title:'', isAddClass: true}, // 질문코드 신규생성 (총평 : 15번)
        {idx:10, encrsAttrCd:'F990', questCd:'016', answValu:'', ctgeTitle:'CA의견', title:'', isAddCntnt: true, addAnswCntnt: ''} // 질문코드 신규생성 (CA의견 : 16번)
        // {idx:9,  encrsAttrCd:'F990', questCd:'014', answValu:'', ctgeTitle:'잠재고객',     title:'', isAddClass: true,},
      ],
      /** 0차월 답변지 **/
      answList: [        
        [{value:'01', text:'70% ↓'},{value:'02', text:'70% ↑'},{value:'04', text:'90% ↑'}],
        [{value:'A', text:'우수'},{value:'C', text:'보통'},{value:'D', text:'미흡'}],
        [{value:'A', text:'우수'},{value:'C', text:'보통'},{value:'D', text:'미흡'}],
        [{value:'01', text:'있음'},{value:'02', text:'없음'}],
        [{value:'01', text:'주부'},{value:'02', text:'자영업'},{value:'03', text:'사무직'},{value:'04', text:'보험영업'},{value:'05', text:'교육(학원/유치원)'},{value:'06', text:'서비스직'},{value:'07', text:'투잡'},{value:'08', text:'기타'}],
        [{value:'01', text:'있음'},{value:'02', text:'없음'}],
        [{value:'01', text:'있음'},{value:'02', text:'없음'}],
        [{value:'01', text:'0.5H(30분) 미만'},{value:'02', text:'0.5~1.0H'},{value:'03', text:'1.0~1.5H'},{value:'04', text:'1.5H 초과'}],
        [{value:'A', text:'A : 영업기반 및 역량 탄탄하여 영업잠재력 매우 양호'},{value:'B', text:'B : 영업잠재력 양호한 수준'},{value:'C', text:'C : 정착 불안하나 교육 및 코칭으로 보완가능'},{value:'D', text:'D : 정착 어려울 것으로 예상'}]
        // [{value:'01', text:'70% ↓'},{value:'02', text:'70% ↑'},{value:'03', text:'80% ↑'},{value:'04', text:'90% ↑'}], // 교육참석률
        // [{value:'01', text:'30명 ↓'},{value:'02', text:'30명 ↑'},{value:'03', text:'50명 ↑'},{value:'04', text:'100명 ↑'}], // 잠재고객
        // [{value:'A', text:'우수'},{value:'B', text:'양호'},{value:'C', text:'보통'},{value:'D', text:'미흡'}] // 총평
      ],
      currentIdx: 0, // 현재 문항 index
      /* 문항 스와이퍼 옵션 */
      swiperOption: {
        centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
      },
      traineNm: '',
      traineEno: '',
      encrsYm: moment(new Date()).format('YYYY-MM'),
      fofNm: '',
      closeFn: '', // X버튼 눌렀을때 호출 함수

      isTxtFocus: false, // 모바일 키패드 영향으로 입력란 포커스시 margin-bottom Class 적용
      modalFlag: true // close Modal Flag
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)

    const lv_Vm = this
    this.fn_Init()

    // 문항에 맞는 답변지 셋팅
    this.questList.forEach((item,index) => {
      item.answList = lv_Vm.answList[index]
    })
  },  
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    numberOfPage() {
      return this.questList.length
    },
    mo_pagination() {
      return {
        rowsPerPage: 1,
        currentPage: this.currentIdx + 1,
        totalPages: 10,
        totalItems: this.questList.length
      };
    },    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    questList() {
      this.isAllChk = this.fn_Validation()
    },
    caOpinAddCntnt() {
      this.isAllChk = this.fn_Validation()
    },
    jobAddCntnt() {
      this.isAllChk = this.fn_Validation()
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 화면 진입시, 최초실행 함수
     *********************************************************/
    fn_Init() {
      // console.log('pData450M', this.pData450M)
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보

      this.traineNm = this.pData450M.traineNm
      this.traineEno = this.pData450M.traineEno
      this.encrsYm = moment(this.pData450M.encrsYm).format('YYYY-MM')
      this.fofNm = this.pData450M.traineFofOrgNm

      // 처음 작성시 wrtCmpltYn 값은 공백, 임시저장: 'N', 제출: 'Y'
      if (this.pData450M.wrtCmpltYn === "N") {
        this.fn_SelCntnt()
        this.isFirst = false
      } else {
        this.isFirst = true
      }
    },
    /*********************************************************
     * Function명: fn_SelCntnt
     * 설명: 입력값 조회 함수
     *********************************************************/
    fn_SelCntnt() {
      const lv_Vm = this

      let pParams = {
        traineEno : this.pData450M.traineEno,
        EncrsAttrCd: 'F990',
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr
      }
      let trnstId = 'txTSSRC70S3'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            // console.log('임시저장 조회', response.body)
            let result = response.body.invwCntntList
            lv_Vm.invwCntntList = result

            // 임시저장 데이터 바인딩
            lv_Vm.fn_SetInvwData(result)

            lv_Vm.isNoData = false
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SetInvwData
     * 설명: 입력값 데이터 셋팅
     *********************************************************/
    fn_SetInvwData(result) {
      const lv_Vm = this
      let invwData = _.cloneDeep(result)

      this.questList.forEach(item => {
        for (let i=0; i<invwData.length; i++) {
          if (item.questCd === invwData[i].invwQuestCd) {
            item.answValu = invwData[i].invwAnswNo

            if (invwData[i].invwQuestCd === '008' && invwData[i].invwAnswNo === '08' && !this.$bizUtil.isEmpty(invwData[i].addmAnswCntnt)) {
              lv_Vm.jobAddCntnt = invwData[i].addmAnswCntnt
            } else if (invwData[i].invwQuestCd === '016' && !this.$bizUtil.isEmpty(invwData[i].addmAnswCntnt)) {
              if (invwData[i].addmAnswCntnt === '-9999') { // 1. CA 의견이 빈 값으로 임시저장을 누를 경우
                lv_Vm.caOpinAddCntnt = ''
              } else { // 2. CA 의견이 빈 값이 아닌 경우
                lv_Vm.caOpinAddCntnt = invwData[i].addmAnswCntnt
              }
            }
          }
        }
        // if (item.questCd === '' && item.idx === 10) {
        //   item.answValu = lv_Vm.pData450M.obsOpinCd
        // }
      })
      let invwEmty = this.questList.filter(item=>{
        return item.answValu === ''
      })

      // 임시저장했을경우 해당 index + 1 로 이동 (저장 안한 문항중 0번째 문항)
      if (invwEmty.length>0) {
        this.$refs.carousel.moveTo(invwEmty[0].idx-1)
      } else {
        this.$refs.carousel.moveTo(invwData.length)
      }
      
      // 데이터 바인딩후, 입력값 체크
      this.fn_Validation()
    },
    /*********************************************************
     * Function명: fn_SaveCntnt
     * 설명: 임시저장 클릭시, DB 저장후 토스트 안내 메세지
     *********************************************************/
    fn_SaveCntnt() {
      const lv_Vm = this
      let answList = this.fn_GetAnswList()

      let pParams = {
        traineEno   : this.pData450M.traineEno,
        EncrsAttrCd: 'F990',
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr,
        serviceType: '01', // 01: save, 02: submit
        obsOpinCd: this.obsOpinCd,
        invwCntntList: answList,
        wrtEno: this.userInfo.userId,
      }

      let trnstId = 'txTSSRC70I2'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            // console.log(response.body)
            let result = response.body.invwCntntList
            lv_Vm.invwCntntList = result

            lv_Vm.$refs.snackbar.show({ text: '임시저장 되었습니다.' })
            lv_Vm.$emit('onSaveInvwCntnt',{selectItem: lv_Vm.pData450M, sParams:pParams})

            lv_Vm.isNoData = false
            lv_Vm.modalFlag = false

          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SubmCntnt
     * 설명: 제출 클릭시, DB 저장후 팝업 닫기
     *********************************************************/
    fn_SubmCntnt() {
      const lv_Vm = this
      let answList = this.fn_GetAnswList()

      let pParams = {
        traineEno : this.pData450M.traineEno, // '0001845209',
        EncrsAttrCd: 'F990',
        encrsYm: this.pData450M.encrsYm,
        encrsDgr: this.pData450M.encrsDgr,
        obsOpinCd: this.obsOpinCd,
        serviceType: '02', // 01: save , 02: submit
        invwCntntList: answList
      }
      let trnstId = 'txTSSRC70I2'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // console.log(response.body)
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            let result = response.body.invwCntntList
            lv_Vm.invwCntntList = result

            lv_Vm.isNoData = false
            lv_Vm.modalFlag = false

            lv_Vm.$emit('onPopupSubmit', {selectItem: lv_Vm.pData450M, sParams:pParams.serviceType})
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_GetAnswList
     * 설명: 문항 답변 선택지 getter
     *********************************************************/
    fn_GetAnswList() {
      const lv_Vm = this
      let rtnList = []
      
      lv_Vm.obsOpinCd = " "      
      this.questList.forEach((qItem) => {
        if (_.isEmpty(qItem.answList)) { // 1. 10번 CA 의견
          if (!_.isEmpty(lv_Vm.caOpinAddCntnt)) {
            rtnList.push({invwQuestCd: qItem.questCd, invwAnswNo: '0', addmAnswCntnt: lv_Vm.caOpinAddCntnt})
          } else {
            rtnList.push({invwQuestCd: qItem.questCd, invwAnswNo: '0', addmAnswCntnt: '-9999'})
          }          
        } else { // 2. 10번 CA 의견 외 문항
          let answObj = _.cloneDeep(qItem.answList.filter(aItem =>{
                        return qItem.answValu === aItem.value
                      })[0])
          if (_.isEmpty(answObj)) return
          
          if (qItem.questCd === '008') { // 전 직장 문항
            if (qItem.answValu === '08') {
              answObj.text = "기타) "+ lv_Vm.jobAddCntnt.replace("기타) ", "")
            }
          } else if (qItem.questCd === '015') { // 총평 문항
            lv_Vm.obsOpinCd = answObj.value
          }
          
          rtnList.push({invwQuestCd: qItem.questCd, invwAnswNo: answObj.value, addmAnswCntnt: answObj.text})          
        }
      })
      
      return rtnList
    },
    /*********************************************************
     * Function명: fn_NextInvw
     * 설명: 답변 선택시, 슬라이드
     *       008번 문항의 기타 선택시 슬라이드 pass
     *********************************************************/
    fn_NextInvw(item) {
      if (this.isFirst) this.isFirst = false
      
      if (item.questCd === '008' && item.answValu === '08') {
        // pass
      } else {
        this.$refs.carousel.smoothMoveTo(item.idx)
      }

      this.fn_Validation()
      
    },
    /*********************************************************
     * Function명: fn_Validation
     * 설명: 모든 문항 답변했는지 여부
     *********************************************************/
    fn_Validation() {
      const lv_Vm = this
      let rtnCnt = 0
      let rtn = false

      this.questList.forEach(item => {
        if (item.questCd === '008' && item.answValu === '08') {
          if (lv_Vm.jobAddCntnt.trim() !== '') {
            rtnCnt += 1
          } else {
            rtnCnt -= 1
          }
        } else if (item.questCd === '016') {
          if (lv_Vm.caOpinAddCntnt.trim() !== '') {
            rtnCnt += 1
          } else {
            rtnCnt -= 1
          }
        } else {
          if (item.answValu !== '') {
            rtnCnt += 1
          } else {
            rtnCnt -= 1
          }
        }
      })
      
      if (rtnCnt === this.questList.length) {
        rtn = true
      }

      return rtn
    },
    /******************************************************************************
     * Function명 : fn_ConfmModalOpen / fn_ConfmModalClose
     * 설명       : 작성중 팝업닫을 시 안내 Modal Open/Close
     ******************************************************************************/
    fn_ConfmModalOpen(closeFn) {
      this.closeFn = closeFn // before-close 함수 실행
      if (!this.modalFlag) { // 제출시에만, PopUp Close 함수 실행
        closeFn()
        return
      }
      this.$refs.refConfirmModal.open()
    },
    fn_ConfmModalClose() { this.$refs.refConfirmModal.close() },
    /********************************************************************************************
     * 함수 : fn_ClosePopup
     * 설명 :알림 팝업 닫을 때 호출하여 처리하는 함수
    ********************************************************************************************/
    // 그냥 알림 팝업 닫을 때 호출 하면 됨
    fn_ClosePopup (closeFn) {      
      this.closeFn = closeFn
      if (!this.modalFlag) {
        closeFn()
        return      
      }

      let alertMsg = '작성중인 화면에서 나가시겠습니까?<br>"예" 선택 시 작성된 내용은 저장되지 않습니다.'
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          type: 'B',
          content: alertMsg,
          title_pos_btn: '예',
          title_neg_btn: '아니오',
        },
        listeners: {
          onPopupConfirm: () => {            
            closeFn()
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      })
    },
    /*********************************************************
     * 설명: onQuestionChange / onMove 
     *        <mo-carousel> 태그에 필요 함수
     *********************************************************/
    onQuestionChange(idx) {
      this.currentIdx = idx;
    },
    onMove(idx) {
      this.$refs.carousel.smoothMoveTo(idx - 1);
    },
    /*****************************************************************
     * 설명: fn_TxtFocus / fn_TxtFocusOut
     *        전 직장 문항 - 기타입력시, 키패드로 인해 class 유동적 추가
     *****************************************************************/
    fn_TxtFocus() {
      this.isTxtFocus = true
    },
    fn_TxtFocusOut() {
      this.isTxtFocus = false
    }
  }
}
</script>

/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC463P
 * 화면 설명: CDF관리 화면 - 0차월 속성관찰표 확인
 * 화면 접근권한: [조회]지역단스텝(단장, 파트장, 지점장)
 * 작 성 일: 2022.07.12
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="0차월 관찰표 보기" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box gray">
        <div class="row">
          <div class="title">
            <span>교육생</span>
          </div>
          <div class="value">
            <span>{{ pData450M.traineNm }}({{ pData450M.traineEno }})</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>입과월</span>
          </div>
          <div class="value">
            <span>{{ encrsYm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>지점</span>
          </div>
          <div class="value">
            <span>{{ pData450M.traineFofOrgNm }}</span>
          </div>
        </div>
        <div class="row">
          <div class="title">
            <span>작성</span>
          </div>
          <div class="value">
            <span>{{ pData450M.wrtNm }}({{ pData450M.wrtEno }})</span>
          </div>
        </div>
      </ur-box-container>

      <div v-for="(ctgeItem,idx) in lv_InvwCtgeList" :key="idx" style="width:100%">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box p4524" v-if="idx <= 1">
            <span class="txt-title">{{ctgeItem.title}}</span> 
            <div class="row" v-for="ctgeItemList in lv_InvwCtgeList[idx]" :key="ctgeItemList.invwQuestCd">
              <div class="title w120">
                <span>{{ ctgeItemList.title }}</span>
              </div>
              <div class="value">
                <span>{{ ctgeItemList.addAnswCntnt }}</span>
              </div>
            </div>           
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-txt-box p4524" v-else>
          <span class="txt-title">{{ctgeItem.title}}</span>
          <div class="row">
            <div class="title">
              <span>{{ ctgeItem.length > 0 && ctgeItem[0].addAnswCntnt ? ctgeItem[0].addAnswCntnt : '' }}</span>
            </div>
            <div class="value"></div>
          </div>
        </ur-box-container>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  name: 'MSPRC463P',
  screenId: 'MSPRC463P',
  mixins:[Screen],
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    pData450M: {
      type: Object
    }
  },
  data() {
    return {
      title: '',
      encrsYm: '', // 입과월
      obsOpinTxt: '', // CA관찰 의견
      prspCust: '', // 잠재고객 값

      lv_InvwCtgeList: [], // 카테고리별 셋팅
      lv_InvwCntntList: [], // 문항별 셋팅

      /** 0차월 질문지 **/
      // questCd: 임의 수정 금지, 관리자 페이지 엑셀 다운로드 영향이 있습니다. questCd 코드: 테이블 정의서(TTSR00022) 참조
      // CA 종합 평가 문항은 정의된 questCd 코드가 없음.
      questList: [
        {idx:1,  questCd:'001', ctgeTitle:'0차월과정', title:'교육참석률'},
        {idx:2,  questCd:'012', ctgeTitle:'0차월과정', title:'Job 수용성'},
        {idx:3,  questCd:'013', ctgeTitle:'0차월과정', title:'교과목 이해도'},
        {idx:4,  questCd:'007', ctgeTitle:'속성',      title:'신용문제'},
        {idx:5,  questCd:'008', ctgeTitle:'속성',      title:'전 직장', isAddCntnt: true, addAnswCntnt: ''},
        {idx:6,  questCd:'009', ctgeTitle:'속성',      title:'육아문제'},
        {idx:7,  questCd:'010', ctgeTitle:'속성',      title:'가족반대'},
        {idx:8,  questCd:'011', ctgeTitle:'속성',      title:'원거리'},        
        {idx:9,  questCd:'015', ctgeTitle:'총평',      title:''},
        {idx:10, questCd:'016', ctgeTitle:'CA의견',    title:'', isAddCntnt: true, addAnswCntnt: ''}
        // {idx:9,  questCd:'014', ctgeTitle:'잠재고객',   title:''},
      ],
      obsOpin: [], // CA관찰 의견 선택값
      obsOpinList: [{value:'A', text:'A : 영업기반 및 역량 탄탄하여 영업잠재력 매우 양호'},{value:'B', text:'B : 영업잠재력 양호한 수준'},{value:'C', text:'C : 정착 불안하나 교육 및 코칭으로 보완가능'},{value:'D', text:'D : 정착 어려울 것으로 예상'}]
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    this.fn_Init()
    this.encrsYm = moment(this.pData450M.encrsYm).format('YYYY-MM')
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_Init
     * 설명: 화면 진입시, 최초실행 함수
     *********************************************************/
    fn_Init() {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      this.fn_SelCntnt()
    },
    /*********************************************************
     * Function명: fn_SelCntnt
     * 설명: 0차월 속성관찰표 입력값 조회
     *********************************************************/
    fn_SelCntnt() {
      const lv_Vm = this

      let pParams = {
        traineEno : this.pData450M.traineEno, // 교육생 사번
        EncrsAttrCd: 'F990', // 0차월
        encrsYm: this.pData450M.encrsYm, // 입과월
        encrsDgr: this.pData450M.encrsDgr // 입과차수
      }
      let trnstId = 'txTSSRC70S3'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.invwCntntList)) {
            // console.log(response.body)
            let result = response.body
            lv_Vm.invwCntntList = result.invwCntntList
            
            // 문항 답변값 셋팅
            lv_Vm.fn_SetInvwCntnt(result)
            lv_Vm.isNoData = false
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /*********************************************************
     * Function명: fn_SetInvwCntnt
     * 설명: 0차월 속성관찰표 답변값 셋팅
     *********************************************************/
    fn_SetInvwCntnt(rData) {
      const lv_Vm = this
      let rtnList = []      
      let invwItem = rData.invwCntntList
      let isInvwQuestCd = false // 총평, CA의견 등 질문코드 있는지 확인 (2/20 이전에는 질문코드 없음)
      invwItem.forEach(item => {
        for (let i in lv_Vm.questList) {
          if (item.invwQuestCd === lv_Vm.questList[i].questCd) {
            let currItem = lv_Vm.questList[i]
            rtnList.push({idx: i, invwQuestCd:item.invwQuestCd, ctgeTitle: currItem.ctgeTitle, title: currItem.title, invwAnswNo:item.invwAnswNo, addAnswCntnt: item.addmAnswCntnt})
            // 잠재고객 변경 설문지에는 없음
            // if (item.invwQuestCd === '014') {
            //   this.prspCust = item.addmAnswCntnt
            // }
            if (item.invwQuestCd === '015') {
              isInvwQuestCd = true
            }            
            return
          }
        }
      })

      let tmpObsOpin = ''
      if (!isInvwQuestCd) {
        // CA관찰의견 셋팅
        if(rData && rData.obsOpinCd) {
          tmpObsOpin = this.obsOpinList.filter(item=>{
            return item.value === rData.obsOpinCd
          })[0].text
        }
        rtnList.push({idx: 9, invwQuestCd: '015', addAnswCntnt: tmpObsOpin})
        rtnList.push({idx: 10, invwQuestCd: '016', addAnswCntnt: '없음'})
      }

      // // CA관찰의견 셋팅
      // this.obsOpin = this.obsOpinList.filter(item=>{
      //   return item.value === rData.obsOpinCd
      // })
      // this.obsOpinTxt = this.obsOpin[0].text

      // invwQuestCd 기준 정렬(오름차순)
      // rtnList = rtnList.sort(function(a,b) {
      //   return a.invwQuestCd - b.invwQuestCd
      // })

      this.lv_InvwCtgeList = this.fn_SetClfyCtge(rtnList)
      this.lv_InvwCntntList = rtnList
    },
    /*********************************************************
     * Function명: fn_SetClfyCtge
     * 설명: 답변지 카테고리별 셋팅
     *********************************************************/
    fn_SetClfyCtge(rData) {
      // console.log('fn_SetClfyCtge >', rData)
      let rtnList = []
      let dataList1 = []
      let dataList2 = []
      let dataList3 = []
      let dataList4 = []

      dataList1.title = '0차월 과정'
      dataList2.title = '속성'
      dataList3.title = '총평'
      dataList4.title = 'CA의견'
      
      rData.forEach(item => {
        switch (item.invwQuestCd) {
          case '001':
          case '012':
          case '013':
            dataList1.push(item)
            break
          case '007':
          case '008':
          case '009':
          case '010':
          case '011':
            dataList2.push(item)
            break
          case '015':
            dataList3.push(item)
            break
          case '016':
            dataList4.push(item)
            break
        }        
      })

      rtnList = [dataList1, dataList2, dataList3, dataList4]      
      return rtnList
    }
  }
}
</script>
